import { CONNECTIONS } from "~/shared/config";
import { capitalizeFirstLetter } from "~/shared/utils";
import {
  Connection,
  ConnectionResponse,
  ConnectionStatus,
  FetchInitialMetricsJobStatus,
  statusToDisplayStatus,
  statusToTooltip,
} from "~/types/Connection";
import { Config, Provider } from "~/types/shared";

const TEN_MINUTES = 10 * 60 * 1000;
const FIFTEEN_SECONDS = 15 * 1000;

export const useConnection = () => {
  const providerToLogo = {
    [Provider.META]: "/img/meta.png",
    [Provider.TIKTOK]: "/img/tiktok.png",
    [Provider.GOOGLE]: "/img/google.png",
    [Provider.ADTRIBUTE]: "/img/adtribute.png",
    [Provider.GOOGLE_SHEETS]: "/img/google-sheets.png",
    [Provider.HYROS]: "/img/hyros.png",
    LINKEDIN: "/img/linkedin.png",
  };

  const getProviderLogo = (provider: string) => {
    if (Object.keys(providerToLogo).includes(provider)) {
      return providerToLogo[provider as Provider];
    }
    return "";
  };

  const getProviderLogos = (provider: Provider) => {
    const providerToLogo = {
      [Provider.META]: ["/img/meta.png"],
      [Provider.TIKTOK]: ["/img/tiktok.png"],
      [Provider.GOOGLE]: ["/img/google.png"],
      [Provider.ADTRIBUTE]: ["/img/adtribute.png"],
      [Provider.GOOGLE_SHEETS]: ["/img/google-sheets.png"],
      [Provider.HYROS]: ["/img/hyros.png"],
    };
    return providerToLogo[provider];
  };

  const providerToDisplayName = (provider: Provider) => {
    const connectionConfig: Array<Config<string>> =
      useGetEnabledConfig(CONNECTIONS);
    const idx = connectionConfig.findIndex((config) => config.id === provider);
    return idx > -1
      ? connectionConfig[idx].displayName
      : capitalizeFirstLetter(provider);
  };

  const getMappedConnections = (
    connections: Array<ConnectionResponse>,
  ): Array<Connection> => {
    const mappedConnections = connections.map((connection) => {
      return {
        ...connection,
        providerId: connection.sub,
        logo: getProviderLogo(connection.provider),
        displayStatus: statusToDisplayStatus[connection.status],
        providerDisplayName: providerToDisplayName(connection.provider),
        tooltip: getConnectionTooltip(
          connection.status,
          connection.displayError,
        ),
      };
    });

    mappedConnections.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    return mappedConnections;
  };

  const getConnectionTooltip = (
    status: ConnectionStatus,
    displayError: string | null,
  ): string => {
    if (status === ConnectionStatus.ERROR && displayError) {
      return displayError;
    }
    return statusToTooltip[status];
  };

  const isErrorStatus = (status: FetchInitialMetricsJobStatus) =>
    status === "Failed";

  const isCompletedStatus = (status: FetchInitialMetricsJobStatus) =>
    status === "Completed";

  // We want to not show "error" to the user, but rather a more friendly message
  // I.e. we will be notified in the backend and fix the issue
  const errorMessageLoadData =
    "Looks like we hit a timeout, but don't worry, your task is still processing in the background. Data will be available in next 2-3 hours.";

  const pingJobStatus = (
    getStatusUrl: string,
    timeout = TEN_MINUTES,
    pollEvery = FIFTEEN_SECONDS,
  ) => {
    const connectionStore = useConnectionStore();

    return new Promise<FetchInitialMetricsJobStatus>((resolve) => {
      const interval = setInterval(async () => {
        const [jobStatusMaybe, shouldContinue] =
          await connectionStore.getJobStatus(getStatusUrl);
        if (!shouldContinue) {
          clearInterval(interval);
          return resolve("Failed");
        }
        if (
          typeof jobStatusMaybe !== "string" &&
          jobStatusMaybe != null &&
          jobStatusMaybe.status !== "Running"
        ) {
          clearInterval(interval);
          clearTimeout(timeoutId);
          return resolve(jobStatusMaybe.status);
        }
      }, pollEvery);

      const timeoutId = setTimeout(() => {
        clearInterval(interval);
        return resolve("Failed");
      }, timeout);
    });
  };

  return {
    getMappedConnections,
    getProviderLogo,
    getProviderLogos,
    providerToDisplayName,
    isErrorStatus,
    errorMessageLoadData,
    pingJobStatus,
    isCompletedStatus,
  };
};
